import {
  CategoryManagementColumns,
  CategoryMonitoringColumns,
  ContactsColumns,
  DEFAULT_CATEGORY_MANAGEMENT_COLUMNS,
  DEFAULT_CATEGORY_MONITORING_COLUMNS,
  DEFAULT_CONTACTS_COLUMNS,
  DEFAULT_HEATMAP_COLUMNS,
  DEFAULT_INCIDENTS_COLUMNS,
  DEFAULT_LEGAL_CONTRACTS_COLUMNS,
  DEFAULT_LOCAL_DIVISION_USERS_COLUMNS,
  DEFAULT_ORDER,
  DEFAULT_ORGANISATION_MANAGERS_COLUMNS,
  DEFAULT_QUOTATIONS_BY_STATUS_COLUMNS,
  DEFAULT_QUOTATION_REVISION_TRACKER_COMMERCIAL_REQUIREMENTS_COLUMNS,
  DEFAULT_QUOTATION_REVISION_TRACKER_INFORMATION_COLUMNS,
  DEFAULT_QUOTATION_REVISION_TRACKER_LOGISTICAL_REQUIREMENTS_COLUMNS,
  DEFAULT_QUOTATION_REVISION_TRACKER_TCO_COLUMNS,
  DEFAULT_QUOTATION_REVISION_TRACKER_TECHNICAL_REQUIREMENTS_COLUMNS,
  DEFAULT_QUOTATION_SOURCING_SCOPE_COLUMNS,
  DEFAULT_RESOURCE_CURRENCIES_COLUMNS,
  DEFAULT_RESOURCE_DOCUMENTS_COLUMNS,
  DEFAULT_RESOURCE_EXCHANGE_RATES_COLUMNS,
  DEFAULT_RESOURCE_INCOTERMS_COLUMNS,
  DEFAULT_RESOURCE_LINKS_COLUMNS,
  DEFAULT_RESOURCE_MDF_CODES_COLUMNS,
  DEFAULT_RESOURCE_PAYMENT_TERMS_COLUMNS,
  DEFAULT_RESOURCE_TERMS_AND_CONDITIONS_COLUMNS,
  DEFAULT_RESOURCE_VARIABLES_COLUMNS,
  DEFAULT_STRATEGY_MANAGEMENT_COLUMNS,
  DEFAULT_SUPPLIER_MANAGEMENT_COLUMNS,
  DEFAULT_SUPPLIER_PERFORMANCE_EVALUATIONS_COLUMNS,
  DEFAULT_SUPPLIER_STRATEGY_COLUMNS,
  DEFAULT_SUPPLIER_STRATEGY_SOURCING_EVENTS_COLUMNS,
  DEFAULT_SUPPLIER_SUPPLY_BASE_MANAGEMENT_SUPPLIER_EVALUATION_COLUMNS,
  DEFAULT_TEMPLATE_MANAGEMENT_COLUMNS,
  DEFAULT_USERS_COLUMNS,
  HeatmapColumns,
  IncidentsColumns,
  LegalContractsColumns,
  LocalDivisionUsersColumns,
  OrganisationManagersColumns,
  Preferences,
  QuotationRevisionTrackerCommercialRequirementsFields,
  QuotationRevisionTrackerInformationFields,
  QuotationRevisionTrackerLogisticalRequirementsFields,
  QuotationRevisionTrackerTCOFields,
  QuotationRevisionTrackerTechnicalRequirementsFields,
  QuotationSourcingScopeColumns,
  QuotationsByStatusColumns,
  ResourceCurrenciesColumns,
  ResourceDocumentsColumns,
  ResourceExchangeRatesColumns,
  ResourceIncotermsColumns,
  ResourceLinksColumns,
  ResourceMdfCodesColumns,
  ResourcePaymentTermsColumns,
  ResourceTermsAndConditionsColumns,
  ResourceVariablesColumns,
  SPESupplierSelectionColumns,
  SPE_EVALUATORS_SELECTION_COLUMNS,
  SPE_SUPPLIER_SELECTION_COLUMNS,
  StrategyManagementColumns,
  SupplierManagementColumns,
  SupplierPerformanceEvaluationsColumns,
  SupplierPerformanceEvaluationsHubColumns,
  SupplierPerformanceEvaluationsLocalDivisionColumns,
  SupplierStrategySourcingEventsColumns,
  SupplierStrategyTaskColumns,
  SupplierSupplyBaseManagementSupplierEvaluationColumns,
  TemplateManagementColumns,
  UsersColumns,
} from '@abb-procure/constants';
import {
  DestroyRef,
  Injectable,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { deepEqual } from 'fast-equals';
import { PreferenceModel } from '../models/preference.model';
import { PreferencesApiService } from '../services/preferences-api.service';
import { Order } from '../types/order.type';
import { StateStorage, StorageStrategy } from '../utils/state-storage.util';
import { UserState } from './user.state';

type PreferencesStateShape = {
  preferences: PreferenceModel[];
};

const initialState: PreferencesStateShape = {
  preferences: [],
};

@Injectable({
  providedIn: 'root',
})
export class PreferencesState {
  preferences = computed(() => this.state().preferences, { equal: deepEqual });

  isNavigationExpanded = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.IsNavigationExpanded,
    );

    return setting?.value !== 'false';
  });

  isSecondaryNavigationExpanded = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.IsSecondaryNavigationExpanded,
    );

    return setting?.value !== 'false';
  });

  isDashboardFiltersExpanded = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.IsDashboardFiltersExpanded,
    );

    return setting?.value !== 'false';
  });

  isSupplierSidePanelExpanded = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.IsSupplierSidePanelExpanded,
    );

    return setting?.value !== 'false';
  });

  isQuotationSidePanelExpanded = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.IsQuotationSidePanelExpanded,
    );

    return setting?.value !== 'false';
  });

  dateFormat = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.DateFormat,
    );

    return setting?.value ?? null;
  });

  quotationSourcingScopeColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.QuotationSourcingScopeColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_QUOTATION_SOURCING_SCOPE_COLUMNS
    ) as (keyof typeof QuotationSourcingScopeColumns)[];
  });

  quotationRevisionTrackerInformationFields = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key ===
        Preferences.QuotationRevisionTrackerInformationFields,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_QUOTATION_REVISION_TRACKER_INFORMATION_COLUMNS
    ) as (keyof typeof QuotationRevisionTrackerInformationFields)[];
  });

  quotationRevisionTrackerTechnicalRequirementsFields = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key ===
        Preferences.QuotationRevisionTrackerTechnicalRequirementsFields,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_QUOTATION_REVISION_TRACKER_TECHNICAL_REQUIREMENTS_COLUMNS
    ) as (keyof typeof QuotationRevisionTrackerTechnicalRequirementsFields)[];
  });

  quotationRevisionTrackerCommercialRequirementsFields = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key ===
        Preferences.QuotationRevisionTrackerCommercialRequirementsFields,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_QUOTATION_REVISION_TRACKER_COMMERCIAL_REQUIREMENTS_COLUMNS
    ) as (keyof typeof QuotationRevisionTrackerCommercialRequirementsFields)[];
  });

  quotationRevisionTrackerLogisticalRequirementsFields = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key ===
        Preferences.QuotationRevisionTrackerLogisticalRequirementsFields,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_QUOTATION_REVISION_TRACKER_LOGISTICAL_REQUIREMENTS_COLUMNS
    ) as (keyof typeof QuotationRevisionTrackerLogisticalRequirementsFields)[];
  });

  quotationRevisionTrackerTCOFields = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.QuotationRevisionTrackerTCOFields,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_QUOTATION_REVISION_TRACKER_TCO_COLUMNS
    ) as (keyof typeof QuotationRevisionTrackerTCOFields)[];
  });

  incidentsColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.IncidentsColumns,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_INCIDENTS_COLUMNS
    ) as (keyof typeof IncidentsColumns)[];
  });

  quotationsByStatusColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.QuotationsByStatusColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_QUOTATIONS_BY_STATUS_COLUMNS
    ) as (keyof typeof QuotationsByStatusColumns)[];
  });

  contactsColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.ContactsColumns,
    );

    const value: (keyof typeof ContactsColumns)[] = setting?.value
      ? JSON.parse(setting.value)
      : DEFAULT_CONTACTS_COLUMNS;

    return value.filter(
      (column: string) => column !== 'Expiration',
    ) as (keyof typeof ContactsColumns)[];
  });

  legalContractsColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.LegalContractsColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_LEGAL_CONTRACTS_COLUMNS
    ) as (keyof typeof LegalContractsColumns)[];
  });

  categoryMonitoringColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.CategoryMonitoringColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_CATEGORY_MONITORING_COLUMNS
    ) as (keyof typeof CategoryMonitoringColumns)[];
  });

  resourceIncotermsColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.ResourceIncotermsColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_RESOURCE_INCOTERMS_COLUMNS
    ) as (keyof typeof ResourceIncotermsColumns)[];
  });

  resourceTermsAndConditionsColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.ResourceTermsAndConditionsColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_RESOURCE_TERMS_AND_CONDITIONS_COLUMNS
    ) as (keyof typeof ResourceTermsAndConditionsColumns)[];
  });

  localDivisionUsersColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.LocalDivisionUsersColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_LOCAL_DIVISION_USERS_COLUMNS
    ) as (keyof typeof LocalDivisionUsersColumns)[];
  });

  resourcePaymentTermsColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.ResourcePaymentTermsColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_RESOURCE_PAYMENT_TERMS_COLUMNS
    ) as (keyof typeof ResourcePaymentTermsColumns)[];
  });

  resourceCurrenciesColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.ResourceCurrenciesColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_RESOURCE_CURRENCIES_COLUMNS
    ) as (keyof typeof ResourceCurrenciesColumns)[];
  });

  resourceVariablesColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.ResourceVariablesColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_RESOURCE_VARIABLES_COLUMNS
    ) as (keyof typeof ResourceVariablesColumns)[];
  });

  resourceExchangeRatesColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.ResourceExchangeRatesColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_RESOURCE_EXCHANGE_RATES_COLUMNS
    ) as (keyof typeof ResourceExchangeRatesColumns)[];
  });

  organisationManagersColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.OrganisationManagersColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_ORGANISATION_MANAGERS_COLUMNS
    ) as (keyof typeof OrganisationManagersColumns)[];
  });

  resourceMdfCodesColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.ResourceMdfCodesColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_RESOURCE_MDF_CODES_COLUMNS
    ) as (keyof typeof ResourceMdfCodesColumns)[];
  });

  resourceLinksColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.ResourceLinksColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_RESOURCE_LINKS_COLUMNS
    ) as (keyof typeof ResourceLinksColumns)[];
  });

  resourceDocumentsColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.ResourceDocumentsColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_RESOURCE_DOCUMENTS_COLUMNS
    ) as (keyof typeof ResourceDocumentsColumns)[];
  });

  heatmapColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.HeatmapColumns,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_HEATMAP_COLUMNS
    ) as (keyof typeof HeatmapColumns)[];
  });

  heatmapProjectsColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.HeatmapProjectsColumns,
    );

    return setting?.value ? (JSON.parse(setting.value) as string[]) : null;
  });

  suppliersOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.SuppliersOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  usersOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.UsersOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  categoriesOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.CategoriesOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  templatesOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.TemplatesOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  supplierStrategySourcingEventsOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.SupplierStrategySourcingEventsOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  surveyActivityEventsOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.SurveyActivityEventsOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  externalContactsOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.ExternalContactsOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  legalContractsOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.LegalContractsOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  incotermsOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.IncotermsOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  termsAndConditionsOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.TermsAndConditionsOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  currenciesOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.CurrenciesOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  variablesOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.VariablesOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  incidentsOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.IncidentsOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  exchangeRatesOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.ExchangeRatesOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  paymentTermsOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.PaymentTermsOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  categoryMonitoringOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.CategoryMonitoringOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  strategyManagementOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.StrategyManagementOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  localDivisionUsersOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.LocalDivisionUsersOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  localDivisionAssignmentsOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.LocalDivisionAssignmentsOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  organisationManagersOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.OrganisationManagersOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  divisionsReviewOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.DivisionsReviewOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  hubsReviewOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.HubsReviewOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  localDivisionsReviewOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.LocalDivisionsReviewOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  resourceLinksOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.ResourceLinksOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  resourceDocumentsOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.ResourceDocumentsOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  heatmapOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.HeatmapOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  SPESupplierSelectionOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.SPESupplierSelectionOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  SPEEvaluatorsSelectionOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.SPEEvaluatorsSelectionOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  favoriteOrderAll = computed(() => {
    const settings = this.preferences()?.filter((preference) =>
      preference.key?.includes(Preferences.FavoriteOrder),
    );

    return settings?.map((setting) =>
      setting.value
        ? {
            value: JSON.parse(setting.value) as Order,
            key: setting.key ?? '',
          }
        : null,
    );
  });

  sourcingOrderAll = computed(() => {
    const settings = this.preferences()?.filter((preference) =>
      preference.key?.includes(Preferences.SourcingOrder),
    );

    return settings?.map((setting) =>
      setting.value
        ? {
            value: JSON.parse(setting.value) as Order,
            key: setting.key ?? '',
          }
        : null,
    );
  });

  sourcingScopeOrderAll = computed(() => {
    const settings = this.preferences()?.filter((preference) =>
      preference.key?.includes(Preferences.SourcingScopeOrder),
    );

    return settings?.map((setting) =>
      setting.value
        ? {
            value: JSON.parse(setting.value) as Order,
            key: setting.key ?? '',
          }
        : null,
    );
  });

  supplierManagementColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.SupplierManagementColumns,
    );

    const values = (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_SUPPLIER_MANAGEMENT_COLUMNS
    ) as (keyof typeof SupplierManagementColumns)[];

    return values.map((value) =>
      (value as string) === 'ProSupplyGuid' ? 'Guid' : value,
    );
  });

  categoryManagementColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.CategoryManagementColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_CATEGORY_MANAGEMENT_COLUMNS
    ) as (keyof typeof CategoryManagementColumns)[];
  });

  templateManagementColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.TemplateManagementColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_TEMPLATE_MANAGEMENT_COLUMNS
    ) as (keyof typeof TemplateManagementColumns)[];
  });

  SPESupplierSelectionColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.SPESupplierSelectionColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : SPE_SUPPLIER_SELECTION_COLUMNS
    ) as (keyof typeof SPESupplierSelectionColumns)[];
  });

  SPEEvaluatorsSelectionColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.SPEEvaluatorsSelectionColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : SPE_EVALUATORS_SELECTION_COLUMNS
    ) as (keyof typeof SPESupplierSelectionColumns)[];
  });

  supplierStrategyTaskColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.SupplierStrategyTaskColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_SUPPLIER_STRATEGY_COLUMNS
    ) as (keyof typeof SupplierStrategyTaskColumns)[];
  });

  supplierStrategySourcingEventsColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.SupplierStrategySourcingEventsColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_SUPPLIER_STRATEGY_SOURCING_EVENTS_COLUMNS
    ) as (keyof typeof SupplierStrategySourcingEventsColumns)[];
  });

  strategyManagementColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.StrategyManagementColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_STRATEGY_MANAGEMENT_COLUMNS
    ) as (keyof typeof StrategyManagementColumns)[];
  });

  usersColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.UsersColumns,
    );
    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_USERS_COLUMNS
    ) as (keyof typeof UsersColumns)[];
  });

  supplierPerformanceEvaluationsColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.SupplierPerformanceEvaluationsColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_SUPPLIER_PERFORMANCE_EVALUATIONS_COLUMNS
    ) as (keyof typeof SupplierPerformanceEvaluationsColumns)[];
  });

  supplierPerformanceEvaluationsHubColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.SupplierPerformanceEvaluationsHubColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_SUPPLIER_PERFORMANCE_EVALUATIONS_COLUMNS
    ) as (keyof typeof SupplierPerformanceEvaluationsHubColumns)[];
  });

  supplierPerformanceEvaluationsLocalDivisionColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key ===
        Preferences.SupplierPerformanceEvaluationsLocalDivisionsColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_SUPPLIER_PERFORMANCE_EVALUATIONS_COLUMNS
    ) as (keyof typeof SupplierPerformanceEvaluationsLocalDivisionColumns)[];
  });

  supplierSupplyBaseManagementSupplierEvaluationColumns = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key ===
        Preferences.SupplierSupplyBaseManagementSupplierEvaluationColumns,
    );

    return (
      setting?.value
        ? JSON.parse(setting.value)
        : DEFAULT_SUPPLIER_SUPPLY_BASE_MANAGEMENT_SUPPLIER_EVALUATION_COLUMNS
    ) as (keyof typeof SupplierSupplyBaseManagementSupplierEvaluationColumns)[];
  });

  supplierPerformanceEvaluationsOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.SupplierPerformanceEvaluationsOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  userSurveyEvaluationsOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) => preference.key === Preferences.UserSurveyEvaluationsOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  supplierEvaluationsSearchOrder = computed(() => {
    const setting = this.preferences()?.find(
      (preference) =>
        preference.key === Preferences.SupplierEvaluationsSearchOrder,
    );

    return (
      setting?.value ? JSON.parse(setting.value) : DEFAULT_ORDER
    ) as Order;
  });

  private state = signal({ ...initialState });
  private readonly preferencesApiService = inject(PreferencesApiService);
  private readonly userState = inject(UserState);
  private readonly destroyRef = inject(DestroyRef);

  constructor() {
    StateStorage.init(this.state, 'preferences', StorageStrategy.LocalStorage);

    if (window.matchMedia('(max-width: 768px)').matches) {
      this.updatePreference(
        new PreferenceModel({
          key: Preferences.IsNavigationExpanded,
          value: 'false',
        }),
      );
    }

    effect(
      () => {
        const current = this.userState.current();

        if (!current) {
          return;
        }

        this.state.update((state) => ({
          ...state,
          preferences: current.preferences ?? [],
        }));
      },
      { allowSignalWrites: true },
    );
  }

  toggleNavigation(): void {
    const preference = new PreferenceModel({
      key: Preferences.IsNavigationExpanded,
      value: this.isNavigationExpanded() ? 'false' : 'true',
    });

    this.updatePreference(preference);

    this.preferencesApiService
      .updatePreferences$(preference)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        // ignore
      });
  }

  toggleSecondaryNavigation(): void {
    const preference = new PreferenceModel({
      key: Preferences.IsSecondaryNavigationExpanded,
      value: this.isSecondaryNavigationExpanded() ? 'false' : 'true',
    });

    this.updatePreference(preference);

    this.preferencesApiService
      .updatePreferences$(preference)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        // ignore
      });
  }

  toggleDashboardFilters(): void {
    const preference = new PreferenceModel({
      key: Preferences.IsDashboardFiltersExpanded,
      value: this.isDashboardFiltersExpanded() ? 'false' : 'true',
    });

    this.updatePreference(preference);

    this.preferencesApiService
      .updatePreferences$(preference)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        // ignore
      });
  }

  toggleSupplierSidePanel(): void {
    const preference = new PreferenceModel({
      key: Preferences.IsSupplierSidePanelExpanded,
      value: this.isSupplierSidePanelExpanded() ? 'false' : 'true',
    });

    this.updatePreference(preference);

    this.preferencesApiService
      .updatePreferences$(preference)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        // ignore
      });
  }

  toggleQuotationSidePanel(): void {
    const preference = new PreferenceModel({
      key: Preferences.IsQuotationSidePanelExpanded,
      value: this.isQuotationSidePanelExpanded() ? 'false' : 'true',
    });

    if (this.isNavigationExpanded() && !this.isQuotationSidePanelExpanded()) {
      this.toggleNavigation();
    }

    this.updatePreference(preference);

    this.preferencesApiService
      .updatePreferences$(preference)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        // ignore
      });
  }

  updatePreferences(preference: PreferenceModel, callback?: () => void): void {
    this.updatePreference(preference);

    this.preferencesApiService
      .updatePreferences$(preference)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response) => {
        if (!response) {
          return;
        }

        callback?.();
      });
  }

  private updatePreference(preference: PreferenceModel): void {
    this.state.update((state) => {
      const index = state.preferences.findIndex(
        (item) => item.key === preference.key,
      );

      if (index !== -1) {
        return {
          ...state,
          preferences: [
            ...state.preferences.slice(0, index),
            preference,
            ...state.preferences.slice(index + 1),
          ],
        };
      }

      return {
        ...state,
        preferences: [...state.preferences, preference],
      };
    });
  }
}
